import { render, staticRenderFns } from "./trans.vue?vue&type=template&id=465fc68d&scoped=true&"
import script from "./trans.vue?vue&type=script&lang=ts&"
export * from "./trans.vue?vue&type=script&lang=ts&"
import style0 from "./trans.vue?vue&type=style&index=0&id=465fc68d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465fc68d",
  null
  
)

export default component.exports